<!--<div class="toolbar" role="banner">-->
<!--  <h1>-->
<!--    <img src="assets/img/logo_icon_white.png" alt="Ayuppie">-->
<!--    Ayuppie-->
<!--  </h1>-->
<!--</div>-->
<div class="tema">
  <label>
    <input type="checkbox"
           [ngModel]="checkbox"
           (change)="onChangeTheme($event)">Tema chiaro
  </label>
</div>
<div class="errorBox"   [ngClass]="{'showError':error}">
  <div class="errorMsg"  *ngIf="error">
    <h2>{{labels?.messaggi?.attenzione}}</h2>
    <div class="msgInfo">
      <p>{{error}}</p>
    </div>
  </div>
</div>
<div class="content" role="main" *ngIf="dataAyuppie && dataAyuppie.name">


  <app-info-modal [labels]="labels"
                  [visible]="modalOut"
                  [title]="modal_title"
                  [description]="modal_desc"
                  [finishes]="modal_solutions"
                  [imgs]="modal_imgs"
                  (onCloseMe)="onHideModal()"></app-info-modal>
  <!--  <div [innerHTML]="dataAyuppie.name"></div>-->

  <!--  <app-hero [data_hero]="data.hero" [data_aiuppie]="dataAyuppie"></app-hero>-->
  <app-hero-black [labels]="labels"
                  [data_aiuppie]="dataAyuppie"
                  [buyer]="dataAyuppie.buyer" [info]="dataAyuppie.info" [user]="dataAyuppie.user"></app-hero-black>

  <!--  <app-video-animation [data_general]="data.general"></app-video-animation>-->

  <!--  <a id="moodboard" href="#"></a>-->
  <app-style-pictures-moodboard [labels]="labels"
                                (onShowStylePic)="onShowGalleryModal($event)"
                                [section_id]="'imgsection_start'"
                                [solutions]="dataAyuppie.solutions"
                                [stylePictures]="dataAyuppie.stylePictures"></app-style-pictures-moodboard>



  <div *ngFor="let scenario of scenarios">

    <app-sections-left-right [labels]="labels"
                             [sections]="scenario.sections"
                             [stylePictures]="dataAyuppie.stylePictures"
                             (onScopriDiPiu)="onShowSectionsModal($event)"
                             (onShowAllPics)="onShowGalleryModal($event)"
    ></app-sections-left-right>


    <app-text-glow-grid [labels]="labels"
                        [is_end]="false"
                        [totaleZone]="dataAyuppie.totaleZone"
                        [data_scenarios]="scenario">
    </app-text-glow-grid>
  </div>

  <app-footer [labels]="labels" [data_aiuppie]="dataAyuppie"></app-footer>
</div>
