import { Component, OnInit, Input } from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-row-image-animation',
  templateUrl: './row-image-animation.component.html',
  styleUrls: ['./row-image-animation.component.scss']
})
export class RowImageAnimationComponent implements OnInit {

  // @Input() data_level: any;
  @Input() section: any;
  @Input() stylePictures: any;
  @Input() section_id:string;

  urls:any;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);

    const pictures = this.section.pictures.map(elm => elm.id);
    // const roba = this.stylePictures.find(elm => {
    //   const trovato = this.pictures.find(el => el.id === elm);
    //   console.log('RowImageAnimationComponent - trovato', trovato  );
    //   return trovato;
    // });
    this.urls  = [];
    let i = -1;
    while (++i < pictures.length){
      const img = this.stylePictures.find(elm => elm.id === pictures[i] );
      if(img) this.urls.push(img.url);
    }
  }

  ngAfterViewInit(): void {

    let revealContainers = document.querySelectorAll("#"+this.section_id+" .reveal");

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset"
        }
      });

      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: -100,
        // ease: Power2.easeOut
      });
      tl.from(image, 1.5, {
        xPercent: 100,
        scale: 1.3,
        delay: -1.5,
        // ease: Power2.easeOut
      });
    });
  }

}
