import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {VideoAnimationComponent} from './video-animation/video-animation.component';
import {HeroComponent} from './hero/hero.component';
import {PanelAnimationComponent} from './panel-animation/panel-animation.component';
import {TextImageLeftRightAnimationComponent} from './text-image-left-right-animation/text-image-left-right-animation.component';
import {RowImageAnimationComponent} from './row-image-animation/row-image-animation.component';
import {TitleAnimationComponent} from './title-animation/title-animation.component';
import {TitleImageAnimationComponent} from './title-image-animation/title-image-animation.component';
import {TitleVerticalImageAnimationComponent} from "./title-vertical-image-animation/title-vertical-image-animation.component";
import {ProductsAnimatedGridComponent} from "./products-animated-grid/products-animated-grid.component";
import {TextGlowGridComponent} from "./text-glow-grid/text-glow-grid.component";
import {ProductsLeftRightComponent} from "./products-left-right/products-left-right.component";
import {TitleAnimationInOutComponent} from "./title-animation-inout/title-animation-inout.component";
import {HeroBlackComponent} from "./hero-black/hero-black.component";
import {StylePicturesMoodboardComponent} from "./style-pictures-moodboard/style-pictures-moodboard.component";
import {SectionsLeftRightComponent} from "./sections-left-right/sections-left-right.component";
import {InfoModalComponent} from "./info-modal/info-modal.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from "@angular/material/dialog";
import {FooterComponent} from "./footer/footer.component";
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgxMasonryModule} from 'ngx-masonry';

@NgModule({
    declarations:[
        AppComponent,
        VideoAnimationComponent,
        HeroComponent,
        HeroBlackComponent,
        PanelAnimationComponent,
        TextImageLeftRightAnimationComponent,
        RowImageAnimationComponent,
        TitleAnimationComponent,
        TitleAnimationInOutComponent,
        TitleImageAnimationComponent,
        TitleVerticalImageAnimationComponent,
        ProductsAnimatedGridComponent,
        TextGlowGridComponent,
        ProductsLeftRightComponent,

        SectionsLeftRightComponent,
        StylePicturesMoodboardComponent,
        FooterComponent,

        InfoModalComponent,
    ],
    imports:[
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDialogModule,
        HttpClientModule,
        FormsModule,
        NgxMasonryModule,
    ],
    providers:[],
    bootstrap:[AppComponent]
})
export class AppModule {
}
