<section class="title-image-animation" [id]="section_id" >
    <div class="title-image-animation__inner" >
        <div class="title-image-animation__images">
            <img class="title-image-animation__image placeholder" src="/assets/img/bg_light_placeholder.jpg"/>
            <img class="title-image-animation__image" [src]="hero_image.url"/>
        </div>
        <div class="title-image-animation__content">
            <div class="title-image-animation__headline">
                <h3>{{aiuppie_title}}</h3>
            </div>
        </div>
    </div>
</section>
