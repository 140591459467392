import { Component, OnInit, Input } from '@angular/core';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import data from '../../assets/json/data.json';

@Component({
  selector: 'app-video-animation',
  templateUrl: './video-animation.component.html',
  styleUrls: ['./video-animation.component.scss'],
})
export class VideoAnimationComponent implements OnInit {

  @Input() data_general: any;

  constructor() { }

  ngOnInit(): void {

    const video = document.querySelector("video");
    let videoDuration;
    video.onloadedmetadata = () => {
      videoDuration = video.duration;
      // console.log("Loaded", videoDuration);
    };

    // gsap.registerPlugin(ScrollTrigger);

    const st = ScrollTrigger.create({
      trigger:".video-animation",
      start:"top top",
      end: '+=2500', // modificare in base alla durata del video
      // end:"bottom+=2000 top",
      markers: false,
      pin: true,
      scrub: true,
      onUpdate: (self) => {
        videoDuration = videoDuration || 10;
        video.currentTime = self.progress * videoDuration;
        // console.log('VideoAnimationComponent - onUpdate - video.currentTime >>> ', video.currentTime  );
      }
    })

  }

}
