import { Component, OnInit, Input } from '@angular/core';
import { gsap, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'products-left-right',
  templateUrl: './products-left-right.component.html',
  styleUrls: ['./products-left-right.component.scss']
})
export class ProductsLeftRightComponent implements OnInit {

  @Input() solutions: any;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterViewInit(): void {
    gsap.utils.toArray(".products-left-right-item .gs_reveal").forEach((elem:any) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view
      // console.log("TextImageLeftRightAnimationComponent .gs_reveal", elem);
      
      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => this.animateFrom(elem), 
        onEnterBack: () => this.animateFrom(elem, -1),
        onLeave: () => this.hide(elem) // assure that the element is hidden when scrolled into view
      });
    });
  }

  animateFrom(elem, direction?) {
    direction = direction | 1;
    
    var x = 0,
        y = direction * 100;
    if(elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if(elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    gsap.fromTo(elem, {x: x, y: y}, {
      duration: 1.25, 
      x: 0,
      y: 0,
      ease: "expo", 
      overwrite: "auto"
    });
    gsap.fromTo(elem, { autoAlpha: 0}, {
      duration: 2,
      autoAlpha: 1,
      ease: Sine.easeOut,
      // overwrite: "auto"
    });
  }
  
  hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
  }

}
