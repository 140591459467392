import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type FinishVO = {
  name: string,
  idBrand?: string,
  brandName?: string,
  idDesigner?: any,
  designerName?: any,
  pictureId?: string,
  url?: string,
  quantity?: number,
  measureLabel?: string,
};

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Input() labels:any;
  @Input() visible:boolean = true;
  @Input() title:string;
  @Input() description:string;
  @Input() finishes:FinishVO[];
  @Input() imgs:[];
  @Input() content:HTMLElement;

  @Output() onCloseMe: EventEmitter<null> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }


  onClose =(evt) =>{
    // this.visible = false;
    this.onCloseMe.emit();
  }

}
