import { Component, OnInit, Input } from '@angular/core';
import {gsap, Quad} from "gsap";

@Component({
  selector: 'app-hero-black',
  templateUrl: './hero-black.component.html',
  styleUrls: ['./hero-black.component.scss']
})
export class HeroBlackComponent implements OnInit {

  @Input() labels:any;
  @Input() data_aiuppie: any;
  @Input() info: any;
  @Input() user: any;
  @Input() buyer: any;

  titoloLungo = false;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);

    const classe = '.hero-black';
    const tl = gsap.timeline({
      defaults: {duration: 1},
      scrollTrigger: {
        trigger: classe,
        // markers: true,
        // start: "top center",
        // end: "bottom",
        // pin: true
      }
    })
        .fromTo('.hero-black h2', { opacity: 0},
            { opacity: 1, ease:Quad.easeOut},.6)
        .fromTo('.hero-black h3', { opacity: 0},
            {opacity: 1, ease:Quad.easeOut},.8)
        .fromTo(classe, {y: 130},
            {y: 0,  ease:Quad.easeOut},.5);

    gsap.timeline({

      scrollTrigger: {
        start:'top 40%',
        end:"top 0%",
        markers:false,
        trigger: '.hero-black .hero-txt',
        scrub: true,
        id:'titolone'
      }
    })
        .fromTo('.hero-black .hero-txt', { opacity: 1},
            { opacity: 0, duration:1})

    this.titoloLungo = (this.data_aiuppie.name.split(' ').filter(elm => elm.length > 10)).length > 0;
  }
}
