<!--<section class="hero" [ngStyle]="{'background-image': 'url(' + data_hero.image.url + ')'}">-->
<section class="hero-black">
    <div class="hero-studio">
        <h3 >{{user?.company?.companyName}}</h3>
        <h2 >{{user?.qualification}} {{user?.name}} {{user?.familyName}}</h2>
    </div>
    <div class="hero-txt">
        <h2 [ngClass]="{'long-title': titoloLungo}">{{data_aiuppie.name}}</h2>
        <h3>{{data_aiuppie.description}}</h3>
    </div>

    <div class="hero-client">
        <h3 *ngIf="buyer">{{buyer.name}} {{buyer.familyName}}</h3>
    </div>
</section>
