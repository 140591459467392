import { Component, OnInit, Input } from '@angular/core';
import {gsap, Quad} from "gsap";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() data_aiuppie: any;
  @Input() labels:any;

  constructor() { }

  ngOnInit(): void {
    //Al momento non prevede animazioni

    //
    // const classe = '.footer';
    // const tl = gsap.timeline({
    //   defaults: {duration: 1},
    //   scrollTrigger: {
    //     trigger: classe,
    //     // markers: true,
    //     // start: "top center",
    //     // end: "bottom",
    //     // pin: true
    //   }
    // })
    //     .fromTo('.footer h2', { opacity: 0},
    //         { opacity: 1, ease:Quad.easeOut},.6)
    //     .fromTo('.footer h3', { opacity: 0},
    //         {opacity: 1, ease:Quad.easeOut},.8)
    //     .fromTo(classe, {y: 130},
    //         {y: 0,  ease:Quad.easeOut},.5);
    //
    // gsap.timeline({
    //
    //   scrollTrigger: {
    //     start:'top 40%',
    //     end:"top 0%",
    //     markers:false,
    //     trigger: '.footer .hero-txt',
    //     scrub: true,
    //     id:'titolone'
    //   }
    // })
    //     .fromTo('.footer .hero-txt', { opacity: 1},
    //         { opacity: 0, duration:1})

  }
}
