import {Component, Output} from '@angular/core';
// import data from '../assets/json/data.json';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {skipWhile, take} from "rxjs/operators";
import { VERSION } from '../../utils/version';

const isEmptyObject = (obj:any):boolean => (obj && (Object.keys(obj).length === 0));


@Component({
    selector:'app-root',
    templateUrl:'./app.component.html',
    styleUrls:['./app.component.scss'],
    host:{
        '(window:resize)':'onResize($event)'
    }
})

export class AppComponent {

    @Output() modalOut:boolean = false;
    @Output() modal_title:string;
    @Output() modal_desc:string;
    @Output() modal_solutions:[];
    @Output() modal_imgs:[];

    checkbox;
    title = 'ayuppie';

    dataAyuppie;
    scenarios;
    error;

    // sections = [];

    //per le gestione della lingua, da capire in un prossimo futuro
    labels;
    // dal doc v2: ayuppie.space (Url server staging) - ayuppie.com (Url server pubblico) ma non ancora attivo
    ayuppie_path = 'https://ayuppie.com/api/project/get-public-project-v2/';
    // path_lang = './assets/json/';

    // private lang:string;
    private idFinale:string;

    constructor(private dialog:MatDialog,
                private _route:ActivatedRoute,
                private http:HttpClient) {
    }

    ngOnInit() {
        window["version"] = VERSION.version;
        console.log('v.', VERSION.version  );
        //console.log("DATA", data);
        gsap.registerPlugin(ScrollTrigger);

        // leggo i parametri dalle queryParams
        this.getQueryParams();

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    onResize(event) {
        ScrollTrigger.update();
        ScrollTrigger.defaults({
            markers:false,
        })
    }

    /**
     * carica il json da un path
     * @param path
     */
    getJSON(path):Observable<any> {
        return this.http.get(path);
    }

    getQueryParams() {

        this._route.queryParams
            .pipe(
                skipWhile(params => isEmptyObject(params)),
                take(1),
            )
            .subscribe(params => {
                // console.log('AppComponent - lang to load >>> ', params.lang);
                console.log('AppComponent - id to load >>> ', params.id);
                // this.lang = params.lang? params.lang:'it';

                this.idFinale = params.id;
                // console.log('AppComponent - PROCEDO CON LA LINGUA: >>> ', this.lang);

                // le labels sono dentro il json della pagina
                // this.getLabels();
                this.error = null;
                this.getData();

            }, error => {
                console.warn('ATTENZIONE >>> ', error);
                this.error = 'Errore lettura queryParams';

            });

        this.checkTimeOut();
    }

    checkTimeOut() {
        // messo un timeout giusto per gestire il caso in cui NON viene passato nulla nei queryparams, 3 secondi dovremmbero bastare.
        window.setTimeout(()=>{
            if(!this.idFinale){
                this.error = 'Attenzione: Id mancante';
            }
        }, 3000);
    }

    /**
     * carica il json della lingua impostata da this.lang - se non la recupera forza it
     */
    // getLabels() {
    //     const langJsonPromise = this.getJSON(this.path_lang + this.lang + '.json');
    //     langJsonPromise
    //         .pipe(take(1))
    //         .subscribe(data => {
    //             // console.log('AppComponent -  - this.labels >>> ', this.labels  );
    //             this.labels = data;
    //             this.getData();
    //         }, err => {
    //             console.warn('NO LANG FILE >>> ', err);
    //             this.error = 'Errore caricamento file della lingua: ' + this.lang;
    //         });
    //
    // }

    /**
     * caricamento JSON di AYUPPI
     */
    getData() {

        // let id1 = '60c3333198b03431707ed2cd'; // demo 1
        // let id2 = '60d195c8ab58acc35f668306'; /// demo 2 GAVA

        const json_locale = './assets/json/'+this.idFinale+'.json';
        const ayuppieJsonPromise = this.getJSON(json_locale);

        // recupero l'id dalla pagina
        // const ayuppieJsonPromise = this.getJSON(this.ayuppie_path + this.idFinale);
        ayuppieJsonPromise
            .pipe(take(1))
            .subscribe((myJson) => {

                    this.dataAyuppie = myJson;
                    this.labels = myJson.labels;
                    this.scenarios = [...this.dataAyuppie.scenarios];
                },
                err => {
                    console.warn('ATTENZIONE ERRORE JSON >>> ', err);
                    // this.error = this.labels?.messaggi?.caricamentoJson;
                    this.error = "Errore caricamento JSON. Verificare l'ID passato alla pagina.";

                });

    }

    /***
     * ------------  PARTE DI BLOCCO DELLO SCROLL SOTTO LA MODAL  ---------
     */
    stopBodyScroll = () => {
        //document.getElementById('dialog').classList.add('show')
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.width = '100%';
        body.style.top = `-${scrollY}`;
    }
    releaseBody = () => {
        //document.getElementById('dialog').classList.add('show')

        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    /**
     * ------------  PARTE SHOW + HIDE MODAL  ------------
     */
    onShowSectionsModal = (sel_child) => {
        this.modalOut = true;
        if (sel_child) {
            this.modal_title = sel_child.name;
            this.modal_desc = sel_child.description;
            /**
             * "idSection": "60d19624b8cdde5f2e556335",
             "idPicture": "5f9985a7890ceeaef2848b5c",
             "index": 0,
             "name": "Solution 1",
             "models": [
             {
                "id": "5fda0adc472403215b715992",
                "name": "Arco AP1",
                "idBrand": "5f9981d2890ceeaef2848b55",
                "brandName": "Ideal Lux",
                "idDesigner": null,
                "designerName": "",
                "pictureId": "5fda0aed472403215b715993",
                "url": "https://ayuppiestaging.blob.core.windows.net/pictures/models/5fda0adc472403215b715992/5fda0aed472403215b715993.png"
                }
             ]
             */
            // this.modal_solutions = this.dataAyuppie.solutions.filter(elm => elm.idSection === sel_child.id);
            this.modal_solutions = sel_child?.finishes; //scenario.sections.filter(elm => elm.idSection === sel_child.id);
            this.modal_imgs = [];

            // ScrollTrigger.getAll().forEach(ST => ST.disable());
            // console.log('AppComponent - onShowSectionsModal - this.modal_solutions >>> ', this.modal_solutions  );
            this.stopBodyScroll();

        }
        // console.log('AppComponent - onShowSectionsModal -  >>> ', event  );
    }

    onShowGalleryModal = (sel_child) => {
        this.modalOut = true;
        if (sel_child) {
            // this.modal_title = sel_child.name;
            // this.modal_desc = sel_child.description;
            // this.modal_solutions = this.dataAyuppie.solutions.filter(elm => elm.idSection === sel_child.id);
            this.modal_solutions = [];
            this.modal_imgs = sel_child;
            this.modal_title = this.modal_desc = null;

            this.stopBodyScroll();

        }
    }
    onHideModal = () => {
        this.modalOut = false;
        console.log('AppComponent - onShowSectionsModal -  >>> ', this.modalOut);

        this.releaseBody();
        // ScrollTrigger.getAll().forEach(ST => ST.enable());
        //
        //document.getElementById('dialog').classList.remove('show');
    }

    //
    // openDialog() {
    //     const dialogRef = this.dialog.open(DialogContentExampleDialog);
    //
    //     dialogRef.afterClosed().subscribe(result => {
    //         console.log(`Dialog result: ${result}`);
    //     });
    //
    // }

    onChangeTheme(enable:Event) {
        document.body.classList.toggle(`dark`);
    }
}

//
// @Component({
//     selector:'dialog-content-example-dialog',
//     templateUrl:'dialog-content-example-dialog/dialog-content-example-dialog.html',
// })
// export class DialogContentExampleDialog {
// }
