import { Component, OnInit, Input } from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-title-animation-inout',
  templateUrl: './title-animation-inout.component.html',
  styleUrls: ['./title-animation-inout.component.scss']
})
export class TitleAnimationInOutComponent implements OnInit {
  @Input() data_title: any;
  @Input() data_aiuppie: any;

  private indoor:string = 'Indoor';
  private outdoor:string = 'Outdoor';
  title:string = 'Outdoor';

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);

    this.title = this.data_aiuppie.type === 1?this.indoor:this.outdoor;
    //
    gsap.utils.toArray('.title-animation-inout').forEach((section: any, index) => {
      const w = section.querySelector('.wrapper');
      const [x, xEnd] = (index % 2) ? ['50%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
      
      gsap.fromTo(w, {  x, autoAlpha:0  }, {
        x: xEnd,
        autoAlpha:1,
        scrollTrigger: { 
          trigger: section,
          scrub: true,
          start:"start 100%",
          end:"top 30%",
        }
      });
    });
  }
}
