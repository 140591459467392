import {Component, Input, OnInit} from '@angular/core';
import {gsap, Sine} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

@Component({
    selector:'app-text-glow-grid',
    templateUrl:'./text-glow-grid.component.html',
    styleUrls:['./text-glow-grid.component.scss']
})
export class TextGlowGridComponent implements OnInit {

    @Input() labels:any;
    @Input() is_end:any;
    @Input() data_scenarios:any;
    @Input() totaleZone:number;
    // data_products_for_grid:any;

    show_markers:boolean = false;

    constructor() {

    }

    ngOnInit():void {
        //todo qui andrebbe ricreato un array con valori vuoti che nel caso crea il div spacer e righe a tre elementi
        // this.data_products_for_grid = [...this.data_products];
        // gsap.registerPlugin(ScrollTrigger);
    }

    ngAfterViewInit():void {
        console.log(' ......................  TextGlowGridComponent - ngAfterViewInit -  ');
        gsap.utils.toArray(".glow-grid img").forEach((elem:any, index:number) => {
            this.hide(elem); // assure that the element is hidden when scrolled into view
            // console.log("TextGlowGridComponent .gs_reveal", elem);

            ScrollTrigger.create({
                trigger: elem,
                onEnter:() => this.animateFrom(elem, index),
                // onEnterBack: () => this.animateFrom(elem, -1, i),
                onLeave:() => this.hide(elem) // assure that the element is hidden when scrolled into view
            });
        });

        //

        gsap.utils.toArray(".glow-appear").forEach((elem:any, index:number) => {
            this.hide(elem); // assure that the element is hidden when scrolled into view
            // console.log("TextGlowGridComponent .gs_reveal", elem);

            ScrollTrigger.create({
                trigger: elem,
                onEnter:() => this.animateCardFrom(elem, index),
                // onEnterBack: () => this.animateFrom(elem, -1, i),
                // onLeave:() => this.hide(elem) // assure that the element is hidden when scrolled into view
            });
        });
        // }


        gsap.utils.toArray(".text-glow-grid-title").forEach((elem:any) => {
            gsap.fromTo(elem, {
                maskSize:"+=0", webkitMaskSize:"+=0",
                transformOrigin:"top", autoAlpha:.8
            }, {

                autoAlpha:1,
                maskSize:'+=300%',
                webkitMaskSize:"+=300%",
                ease:Sine.easeInOut,

                scrollTrigger:{
                    start:"top 80%",
                    end:"top 40%",
                    markers:this.show_markers,
                    trigger:elem,
                    scrub:true,
                    // id:"title-glow "
                }
            });
        });

        // const title = document.querySelector('.text-glow-grid .title');

    }

    animateFrom(elem, index) {

        //in
        gsap.fromTo(elem, {scale:.3, y:'-=35%', autoAlpha:0}, {

            scale:1,
            autoAlpha:1,
            ease:Sine.easeInOut,
            y:'+=10%',

            scrollTrigger:{
                start:"top bottom",
                end:"top 10%",
                markers:this.show_markers,
                trigger:elem,
                scrub:true,
                id:"IMG " + index
            }
        });
        //out
        gsap.fromTo(elem, {autoAlpha:1,}, {

            autoAlpha:.05,
            ease:Sine.easeInOut,
            y:'+=50.5%',

            scrollTrigger:{
                start:"top 10%",
                end:"top -50%",
                markers:this.show_markers,
                trigger:elem,
                scrub:true,
                id:"ESC IMG " + index
            }
        });
        // gsap.fromTo(elem, { autoAlpha: 0}, {
        //   duration: 2,
        //   autoAlpha: 1,
        //   ease: Sine.easeOut,
        //   overwrite: "auto"
        // });
    }


    animateCardFrom(elem, index) {

        gsap.fromTo(elem, {autoAlpha:0}, {

            autoAlpha:1,
            ease:Sine.easeInOut,

            scrollTrigger:{
                start:"top-=80 100%",
                end:"top-=80 60%",
                trigger:elem,
                scrub:true,
                markers:this.show_markers,
                id:"CARD IN______________" + index
            }
        });
        gsap.fromTo(elem, {autoAlpha:1}, {

            autoAlpha:0,
            ease:Sine.easeInOut,

            scrollTrigger:{
                start:"top-=80 10%",
                end:"top-=80 top",
                trigger:elem,
                scrub:true,
                markers:this.show_markers,
                id:"CARD OUT_______________" + index
            }
        });
    }

    hide(elem) {
        gsap.set(elem, {autoAlpha: 0});
    }

}
