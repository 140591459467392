<section class="sections-left-right">

<!--    <h2 class="title">SECTIONS</h2>-->

    <div *ngFor="let section of sections; let j = index" class="">
      <h2 class="sections-left-right-name sections-left-right-title" *ngIf="j ===0">{{labels?.sezioneLeftRight?.titolo}}</h2>
      <h2 class="sections-left-right-name">{{section.name}}</h2>

      <!-- CHILDS -->
        <div *ngFor="let child of section.childs; let i = index">

          <span *ngIf="child.picturesURL.length > 0" class="blocco childGrid"
                [ngClass]="{'childGrid_Left': i % 2 == 0, 'childGrid_Right': i % 2 != 0}">
            <div class="childGrid_image">
                <div class="cards" *ngFor="let im of child.picturesURL.slice(0,2)"
                     [ngClass]="{'card_left': i % 2 == 0, 'card_right': i % 2 != 0}"
                     [ngStyle]="{'background-image': 'url(' + im.url + ')'}">
<!--                    <img width="300" *ngIf="im.url" [src]="im.url" alt="">-->
                </div>
                <div class="childGrid_gallery" (click)="onClickGallery(i)"
                     *ngIf="child.picturesURL.length > 2"></div>
            </div>

            <div class="childGrid_text" [ngClass]="{'ipsType_left': i % 2 == 0, 'ipsType_right': i % 2 != 0}">
                <h2 *ngIf="child.name">
                    {{child.name}}
                </h2>
                <h3 *ngIf="child.description">
                    {{child.description}}
                </h3>
                <button class="scopri-btn"
                        [ngClass]="{'scopri-btn-left': i % 2 == 0, 'scopri-btn-right': i % 2 != 0}"
                        (click)="onClickScopri(child)" >{{labels?.btns?.scopri}}</button>
            </div>


          </span>

        </div>


      <!-- FINISHES -->
      <div *ngFor="let child of section.finishes; let i = index">

          <span *ngIf="child.url" class="blocco childGrid"
                [ngClass]="{'childGrid_Left': i % 2 == 0, 'childGrid_Right': i % 2 != 0}">
            <div class="childGrid_image">
                <div class="cards"
                     [ngClass]="{'card_left': i % 2 == 0, 'card_right': i % 2 != 0}"
                     [ngStyle]="{'background-image': 'url(' + child.url + ')'}">
                </div>
            </div>

            <div class="childGrid_text" [ngClass]="{'ipsType_left': i % 2 == 0, 'ipsType_right': i % 2 != 0}">
                <h2 *ngIf="child.name">
                    {{child.name}}
                </h2>
                <h3 *ngIf="child.description">
                    {{child.description}}
                </h3>
              <!-- COMMENTATO visto che è già una finitura -->
<!--                <button class="scopri-btn"-->
<!--                        [ngClass]="{'scopri-btn-left': i % 2 == 0, 'scopri-btn-right': i % 2 != 0}"-->
<!--                        (click)="onClickScopri(child)" >{{labels?.btns?.scopri}}</button>-->
            </div>


          </span>

      </div>
      <!--  TODO Metterci la label dal file delle lingue: -->
      <div class="no-elms" *ngIf="!section.finishes.length && !section.childs.length"> Non sono ancora presenti prodotti in questa sezione.</div>

    </div>

</section>
  
