<div class="modal-guard" *ngIf="visible">

  <div class="modal-box">


    <div class="modal-box-padding">
      <div class="modal-box-content">
        <div class="modal-box-content-section">
          <h2 *ngIf="title" class="modal-title">{{title}}</h2>
          <p *ngIf="description" >{{description}}</p>
<!--          <p *ngIf="imgs.length === 0">Testo di esempio per rendere la modal più alta e mostrare lo scroll interno - poi verranno tolti: <br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus porttitor ligula non auctor. Fusce tortor arcu, maximus quis dictum eget, efficitur vitae eros. Duis varius facilisis turpis, ac vestibulum orci tempus vel. Vivamus hendrerit mi ut accumsan scelerisque. Vivamus ullamcorper, risus vitae consequat laoreet, diam turpis porttitor libero, posuere rutrum odio est quis lectus. Etiam tempor vel sapien ac rhoncus. Nulla eget augue ut dolor fermentum feugiat in ut sapien. Ut at urna imperdiet enim rutrum posuere.-->
<!--            <br>-->
<!--            Ut id lacinia est. Nunc sit amet nulla pharetra, ultrices sapien vel, pellentesque risus. Praesent ac nisl nunc. Vivamus laoreet molestie augue, in faucibus sapien consequat sed. Curabitur hendrerit tristique auctor. Donec et mollis lectus, in fermentum arcu. Ut in consectetur nisi. Etiam porttitor nunc id metus mattis, ut tincidunt turpis bibendum. Nunc fermentum aliquam dui sit amet vulputate. Praesent consequat vestibulum congue.-->
<!--          </p>-->

            <!--              <h3 class="gradient-title">{{child.name}}</h3>-->
            <div *ngFor="let elem of finishes;" class="section-model">
              <div class="section-model-img" [ngStyle]="{'background-image': 'url(' + elem.url + ')'}">
              </div>
              <div class="section-model-texts">
                <h4 class="gradient-title___">{{elem?.name}}</h4>
                <p class="gradient-title___">{{elem?.brandName}}</p>
                <p>{{elem?.designerName}}</p>
<!--                <p>{{elem?.description}}</p>-->
                <p class="model-numbers">{{elem?.quantity}} <span>{{elem?.measureLabel}}</span></p>
              </div>
            </div>
          <div class="modal-box-content-section-imgs" *ngFor="let img of imgs; let i = index">
            <img [src]="img" />
          </div>
        </div>
      </div>


      <div class="modal-box-close" (click)="onClose($event)"></div>
    </div>
  </div>
</div>

