<section class="products-animated-grid">

    <h2 class="products-animated-grid-title">PRODOTTI IN GRIGLIA</h2>

    <div *ngFor="let product of data_products; let i = index" 
         class="products-animated-grid-item prodGrid">
        <div class="prodGrid_image" >
            <div class="card" *ngIf="product.image">
                <img [src]="product.image.url" alt="">
            </div>
        </div>

        <div class="prodGrid_text" >
            <h2 *ngIf="product.title">
                {{product.title}}
            </h2>
            <h3 *ngIf="product.subtitle">
                {{product.subtitle}}
            </h3>
            <p *ngIf="product.text">
                {{product.text}}
            </p>
        </div>
    </div>

</section>
  
