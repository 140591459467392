import {Component, OnInit, Input, HostListener} from '@angular/core';
import {gsap, Sine} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-title-vertical-image-animation',
  templateUrl: './title-vertical-image-animation.component.html',
  styleUrls: ['./title-vertical-image-animation.component.scss'],
})
export class TitleVerticalImageAnimationComponent implements OnInit {

  @Input() data_products: any;

  @HostListener('window:resize', ['$event'])
  onWinResize(event) {
    this.getHPiece();
  }
  
  public hPage:number = 2811;

  constructor() { }


  ngOnInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    // gsap.to(".prodotto", {
    //   scrollTrigger: {
    //     trigger: ".prodotto",
    //     scrub: true,
    //     pin: true,
    //     start: "center center",
    //     end: "bottom -100%",
    //     toggleClass: "active",
    //     markers:true,
    //   },
    //   ease: "power2",
    // });
    // gsap.to(".boxImg", {
    //   scrollTrigger: {
    //     trigger: ".prodotto",
    //     scrub: 0.5,
    //     start: "top bottom",
    //     end: "bottom -300%",
    //     markers:true,
    //   },
    //   ease: "power2",
    //   y: "-30%",
    // });
    this.getHPiece();
  }

  ngAfterViewInit(): void {


    let i = 0;
    let prod = document.getElementById('prod_'+i);
    let prodImgprod = document.getElementById('prod_img_'+i);


    while(prod){
      // this.hPage = prod.offsetHeight*3;
      gsap.to(prod, {
        scrollTrigger: {
          trigger: prod,
          scrub: true,
          pin: true,
          start: "Top top",
          id:'elemProd___'+i,
          end: "bottom -100%",
          toggleClass: "active",
          markers:false,
        },
        ease: "power2",
      });

      const prod_h2 = prod.getElementsByTagName('h2');
      const prod_h3 = prod.getElementsByTagName('h3');
      const prod_p = prod.getElementsByTagName('p');
      let j = 0;
      if(prod_h2 ) {
        this.addTextsAnim(prod_h2, prod, j);
        ++j;
      }
      if(prod_h3 ){
        this.addTextsAnim(prod_h3, prod, j);
        ++j;
      }
      if(prod_p ) {
        this.addTextsAnim(prod_p, prod, j);
        ++j;
      }


      gsap.to(prodImgprod, {
        scrollTrigger: {
          trigger: prod,
          id:'prodimg___'+i,
          scrub: 0.5,
          start: "top top",
          end: "bottom -500%",
          markers:false,
        },
        ease: "power2",
        y: "-50%",
      });
      ++i;
      prodImgprod = document.getElementById('prod_img_'+i);
      prod = document.getElementById('prod_'+i);
    }

    // let blocchi = gsap.utils.toArray('.prodotto .boxImg img');
    // let imgs:Element[] = gsap.utils.toArray('.prodotto .boxImg img');
    //
    //
    // blocchi.forEach((elem:any, index:number) => {


  }

   addTextsAnim(elm, prod, j) {

    if(elm){
      gsap.fromTo(elm,
          { y:(300+j*200)+"px"},
          {
            duration: 1,
            ease: Sine.easeOut,
            overwrite: "auto",
            y:"0",
            scrollTrigger: {
              start: "top bottom",
              trigger: prod,
              scrub: .2
            }
          });
      gsap.fromTo(elm,
          { autoAlpha: 0},
          {
            duration: 1,
            autoAlpha: 1,
            ease: Sine.easeOut,
            overwrite: "auto",
            scrollTrigger: {
              start: "top top",
              trigger: prod,
              scrub: .2+.2*j
            }
          });

    }
  }
  animateFrom(elem, direction?) {
    direction = direction | 1;

    var x = 0,
        y = direction * 100;
    if(elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if(elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  }

  hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
  }

  private getHPiece() {

    let prod = document.getElementById('prod_0');
    if(prod) this.hPage = prod.offsetHeight*3;
  }
}
