<!--<section class="hero" [ngStyle]="{'background-image': 'url(' + data_hero.image.url + ')'}">-->
<section class="footer">
  <div class="footer-title">
    <h3>{{labels?.footer?.title}}</h3>
  </div>
  <div class="footer-intro">
    <div>
      <h3 [innerHTML]="labels?.footer?.intro?.titolo"></h3>
    </div>

    <div>
      <p>
        {{labels?.footer?.intro?.testo}}
      </p>
      <a href="#">{{labels?.footer?.intro?.link}}</a>

    </div>
  </div>


  <div class="footer-info">
    <div class="footer-info-blocco">
      <h4>{{labels?.footer?.condizioni?.titolo}}</h4>
    <div [innerHTML]="labels?.footer?.condizioni?.testo"></div>

    </div>


    <div class="footer-info-blocco">
      <div class="footer-info-blocco-intro">
        <h4>{{labels?.footer?.progetto}}: {{data_aiuppie?.name}}</h4>
        <h4>{{data_aiuppie?.user?.qualification}} : {{data_aiuppie?.user?.name}} {{data_aiuppie?.user?.familyName}}</h4>
        <h4 *ngIf="data_aiuppie?.buyer">{{labels?.footer?.cliente}}: {{data_aiuppie?.buyer?.name}} {{data_aiuppie?.buyer?.familyName}}</h4>
      </div>
      <div class="footer-info-blocco-progetto">
        <div>
          <h4>{{labels?.footer?.progetto}}</h4>
          <p>{{data_aiuppie?.description}}</p>
          <p>{{labels?.footer?.moodboard}}</p>
          <p>{{labels?.footer?.numeri}}</p>
          <p>{{labels?.footer?.acquisto}}</p>
        </div>
        <div>
          <h4>{{labels?.footer?.professionista}}</h4>
          <p>Titolo</p>
          <p>{{data_aiuppie?.user?.name}} {{data_aiuppie?.user?.familyName}}</p>
          <p>Titolo</p>
          <p>Titolo</p>
        </div>

        <div>
          <h4>{{labels?.footer?.advisor}}</h4>
          <p>Titolo</p>
          <p>Titolo</p>
          <p>Titolo</p>
          <p>Titolo</p>
        </div>

        <div *ngIf="data_aiuppie?.buyer">
          <h4>{{labels?.footer?.cliente}}</h4>
          <p>{{data_aiuppie?.buyer?.name}} {{data_aiuppie?.buyer?.familyName}}</p>
          <p>Titolo</p>
          <p>Titolo</p>
          <p>Titolo</p>
        </div>
      </div>
      <h5 class="contatti" *ngIf="data_aiuppie?.buyer?.urlProfile">{{labels?.footer?.info?.vaiAllaPagina}} <a href="{{data_aiuppie?.buyer?.urlProfile}}">{{labels?.footer?.info?.rivenditore}}</a> {{labels?.footer?.info?.oppureChiamaNumero}}</h5>
      <h5 class="contatti" *ngIf="data_aiuppie?.buyer === null || data_aiuppie?.buyer?.urlProfile === null">{{labels?.footer?.info?.chiamaNumero}}</h5>
    </div>

    <div class="footer-info-blocco">
      <h5>{{labels?.footer?.copyright}}</h5>
    </div>

  </div>


</section>
