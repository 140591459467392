<section class="text-image-left-right-animation">    

    <h2 class="title">PRODOTTI</h2>

    <div *ngFor="let product of data_products; let i = index" class="text-image-left-right-animation-item ipsGrid"
        [ngClass]="{'ipsGrid_Left': i % 2 == 0, 'ipsGrid_Right': i % 2 != 0}">
        <div class="ipsGrid_image gs_reveal" [ngClass]="{'gs_reveal_fromLeft': i % 2 == 0, 'gs_reveal_fromRight': i % 2 != 0}">
            <div class="card" *ngIf="product.image">
                <img width="479" [src]="product.image.url" alt="">
            </div>
        </div>

        <div class="ipsGrid_text" [ngClass]="{'ipsType_left': i % 2 == 0, 'ipsType_right': i % 2 != 0}">
            <h2 class="gs_reveal" *ngIf="product.title">
                {{product.title}}
            </h2>
            <h3 class="gs_reveal" *ngIf="product.subtitle">
                {{product.subtitle}}
            </h3>
            <p class="gs_reveal" *ngIf="product.text">
                {{product.text}}
            </p>
        </div>
    </div>

</section>
  
