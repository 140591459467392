import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { gsap, Sine} from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import {NgxMasonryOptions, NgxMasonryAnimations} from 'ngx-masonry';
// import {animate, style} from '@angular/animations';

@Component({
  selector: 'app-style-pictures-moodboard',
  templateUrl: './style-pictures-moodboard.component.html',
  styleUrls: ['./style-pictures-moodboard.component.scss']
})
export class StylePicturesMoodboardComponent implements OnInit {

  @Input() labels:any;
  // @Input() data_level: any;
  @Input() stylePictures: any;
  @Input() solutions: any;
  @Input() section_id:string;

  @Output() onShowStylePic: EventEmitter<Array<string>> = new EventEmitter();

  // animations:NgxMasonryAnimations = {
  //   show: [
  //     style({opacity: 0}),
  //     animate('1000ms ease-in', style({opacity: 1})),
  //   ],
  //   hide: [
  //     style({opacity: '*'}),
  //     animate('400ms ease-in', style({opacity: 0})),
  //   ]
  // }

  public myOptions: NgxMasonryOptions = {
    // animations:{
    //   show: [
    //     style({opacity: 0}),
    //     animate('1200ms ease-in', style({opacity: 1})),
    //   ],
    //   hide: [
    //     style({opacity: '*'}),
    //     animate('400ms ease-in', style({opacity: 0})),
    //   ]
    // }
    horizontalOrder:true,
  };

  titoli:any;

  constructor() { }

  ngOnInit(): void {
    let i = -1;
    while (++i < this.stylePictures.length){
      const idimg = this.stylePictures[i].id;
      const sol = this.solutions?.find(elm => elm.idPicture === idimg );
      if(sol && sol.models[0]) {
        this.stylePictures[i].name = sol?.models[0]?.name;
        this.stylePictures[i].manufacturer = sol.models[0]?.manufacturer;
      }
      // if(img) this.urls.push(img.url);
    }
    // this.stylePictures.find(elm => elm.id === );
  }

  ngAfterViewInit(): void {

    // let revealContainers = document.querySelectorAll("#"+this.section_id+" .spmreveal");
    //
    // revealContainers.forEach((container, index) => {
    //   let tl = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: container,
    //       // toggleActions: "restart none none reset",
    //       scrub: 1,
    //       start:'start 95%',
    //       end:"top 50%",
    //       markers:false,
    //     }
    //   });
    //
    //   tl.set(container, { autoAlpha: 0});
    //   tl.to(container, 1, {
    //     autoAlpha:1,
    //     y:0,
    //     ease: Sine.easeIn,
    //     delay:(index%3)*.5
    //   });
    // });

    let revealBox= document.querySelector("#"+this.section_id+" .grid");

    // tolto l'animazione ora che c'è masondry e messo un fade al blocco intero
    gsap.fromTo(revealBox, {

      autoAlpha:0}, {

      autoAlpha:1,
      ease: Sine.easeInOut,

      scrollTrigger: {
        end:"bottom +=250px",
        scrub: true,
      }
    });


    // const title = document.querySelector('.products-animated-grid .title');
    gsap.utils.toArray(".style-pictures-moodboard-title").forEach((elem:any, index:number) => {

      gsap.fromTo(elem, {
        maskSize:"+=0", webkitMaskSize:"+=0",
        transformOrigin:"top", autoAlpha:.8}, {

        autoAlpha:1,
        maskSize:'+=300%',
        webkitMaskSize:"+=300%",
        ease: Sine.easeInOut,

        scrollTrigger: {
          end:"top 50%",
          markers:false,
          trigger: elem,
          scrub: true,
          id:"title "
        }
      });
    });
  }


  onShowImg(url:string) {
    this.onShowStylePic.emit([url]);
  }
}
