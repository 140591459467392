<section class="text-glow-grid">

    <h2 class="text-glow-grid-title">{{labels?.textGlow?.tuoProgetto}}</h2>

    <div class="glow-grid">
        <div class="bg"></div>
        <div class="glow-imgs">
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
        </div>



        <div class="glow-grid-card large">
            <p class="glow-appear"></p>
            <div class="glow-grid-card-title glow-appear">
                <h2></h2>
            </div>
            <p class="glow-appear"></p>
        </div>
        <div class="glow-grid-card">
            <p class="glow-appear"></p>
            <div class="glow-grid-card-title glow-appear">
                <h2>{{data_scenarios.totaleWatt}}{{labels?.textGlow?.watt}}</h2>
            </div>
            <p class="glow-appear">{{labels?.textGlow?.lumenTotali}} {{data_scenarios.totaleLumen}} {{labels?.textGlow?.lm}}<br/>
                {{labels?.textGlow?.efficienzaTotale}} {{data_scenarios.rapportoEfficienza.toFixed(4)}}</p>
        </div>
        <div class="glow-grid-card"></div>



        <div class="glow-grid-card large">
            <div class="glow-grid-card-title glow-appear">
                <h2>{{data_scenarios.numTotModelli}}</h2>
            </div>
            <p class="glow-appear">{{labels?.textGlow?.modelli}}</p>
        </div>

        <div class="glow-grid-card space">
        </div>
        <div class="glow-grid-card large">
            <div class="glow-grid-card-title glow-appear">
                <h2>{{data_scenarios.totaleProdotti}}</h2>
            </div>
            <p class="glow-appear glow-appear">{{labels?.textGlow?.qProdotti}}</p>
        </div>


        <div class="glow-grid-card large">
            <p class="glow-appear" *ngIf="data_scenarios.temperaturaColorePrevalente">{{labels?.textGlow?.temperaturaColorePiuUsata}}</p>
            <div class="glow-grid-card-title glow-appear" *ngIf="data_scenarios.temperaturaColorePrevalente">
                <h2><span>{{data_scenarios.temperaturaColorePrevalente}} k</span></h2>
            </div>
        </div>
        <div class="glow-grid-card space"></div>


        <div class="glow-grid-card space"></div>
        <div class="glow-grid-card" *ngIf="data_scenarios.totaleProduttori">
            <div class="glow-grid-card-title glow-appear">
                <h2>{{data_scenarios.totaleProduttori}}</h2> {{labels?.textGlow?.produttori}}
            </div>
        </div>

        <div class="glow-imgs">
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
            <img src="/assets/img/gradient__glow__nocol.png" />
<!--            <img src="/assets/img/gradient__glow__nocol.png" />-->
<!--            <img src="/assets/img/gradient__glow__nocol.png" />-->
        </div>
        <div class="glow-grid-card space"></div>
        <div class="glow-grid-card">
            <div class="glow-grid-card-title glow-appear">
                <h2>{{totaleZone}}</h2> {{labels?.textGlow?.zone}}
            </div>
        </div>
        <div class="glow-grid-card space">
        </div>
<!--        <div class="glow-grid-card space">-->
<!--        </div>-->


<!--        <div class="glow-grid-card space">-->
<!--        </div>-->
<!--        <div class="glow-grid-card space">-->
<!--        </div>-->
<!--        <div class="glow-grid-card">-->
<!--            <div class="glow-grid-card-title glow-appear">-->
<!--                <h2></h2>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="glow-grid-card space">-->
<!--        </div>-->

    </div>

    <div class="close_div" *ngIf="is_end"></div>

</section>
  
