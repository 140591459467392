import { Component, OnInit, Input } from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-title-animation',
  templateUrl: './title-animation.component.html',
  styleUrls: ['./title-animation.component.scss']
})
export class TitleAnimationComponent implements OnInit {
  @Input() data_title: any;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);
      
    gsap.utils.toArray('.title-animation').forEach((section: any, index) => {
      const w = section.querySelector('.wrapper');
      const [x, xEnd] = (index % 2) ? ['50%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
      
      gsap.fromTo(w, {  x, autoAlpha:0  }, {
        x: xEnd,
        autoAlpha:1,
        scrollTrigger: { 
          trigger: section, 
          scrub: true,
          start:"start 100%",
          end:"top 30%",
          // markers:true,
        }
      });
    });
  }
}
