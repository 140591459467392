<section class="products-left-right">

    <h2 class="title">PRODOTTI</h2>

    <div *ngFor="let product of solutions; let i = index" class="text-image-left-right-animation-item ipsGrid"
        [ngClass]="{'ipsGrid_Left': i % 2 == 0, 'ipsGrid_Right': i % 2 != 0}">
        <div class="ipsGrid_image gs_reveal" [ngClass]="{'gs_reveal_fromLeft': i % 2 == 0, 'gs_reveal_fromRight': i % 2 != 0}">
            <div class="card" *ngIf="product.models[0]">
                <img width="479" [src]="product.models[0].url" alt="">
            </div>
        </div>

        <div class="ipsGrid_text" [ngClass]="{'ipsType_left': i % 2 == 0, 'ipsType_right': i % 2 != 0}">
            <h2 class="gs_reveal" *ngIf="product.models[0].name">
                {{product.models[0].name}}
            </h2>
            <h3 class="gs_reveal" *ngIf="product.models[0].manufacturer">
                {{product.models[0].manufacturer}}
            </h3>
            <p class="gs_reveal" *ngIf="product.models[0].designerName">
                {{product.models[0].designerName}}
            </p>
        </div>
    </div>

</section>
  
