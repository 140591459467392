import { Component, OnInit, Input } from '@angular/core';
import { gsap, Expo, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-products-animated-grid',
  templateUrl: './products-animated-grid.component.html',
  styleUrls: ['./products-animated-grid.component.scss']
})
export class ProductsAnimatedGridComponent implements OnInit {

  @Input() data_products: any;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterViewInit(): void {
    let i = 0;
    gsap.utils.toArray(".products-animated-grid-item").forEach((elem:any, index:number) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view
      // console.log("ProductsAnimatedGridComponent .gs_reveal", elem);
      
      ScrollTrigger.create({
        // trigger: elem,
        onEnter: () => this.animateFrom(elem, index),
        // onEnterBack: () => this.animateFrom(elem, -1, i),
        onLeave: () => this.hide(elem) // assure that the element is hidden when scrolled into view
      });
    });

    // }

    // const title = document.querySelector('.products-animated-grid .title');
    gsap.utils.toArray(".products-animated-grid-title").forEach((elem:any, index:number) => {

        gsap.fromTo(elem, {scale:.9, y:"-=100",
        maskSize:"+=0", webkitMaskSize:"+=0",
        transformOrigin:"top", autoAlpha:.8}, {

        scale: 1,
        autoAlpha:1,
        y:0,
        maskSize:'+=300%',
        webkitMaskSize:"+=300%",
        ease: Sine.easeInOut,

        scrollTrigger: {
          start:"top 80%",
          end:"top 40%",
          markers:false,
          trigger: elem,
          scrub: true,
          id:"title "
        }
      });
    });
  }

  animateFrom(elem, index, direction?) {
    direction = direction | 1;
    
    // var x = 0,
    //     y = direction * 100;
    // if(elem.classList.contains("gs_reveal_fromLeft")) {
    //   x = -100;
    //   y = 0;
    // } else if(elem.classList.contains("gs_reveal_fromRight")) {
    //   x = 100;
    //   y = 0;
    // }
    gsap.fromTo(elem, {scale:3, transformOrigin:"top", autoAlpha:0}, {

      scale: 1,
      autoAlpha:1,
      ease: Sine.easeInOut,

      scrollTrigger: {
        start:"top 80%",
        end:"top 60%",
        markers:false,
        trigger: elem,
        scrub: true,
        id:"elem "+index
      }
    });
    // gsap.fromTo(elem, { autoAlpha: 0}, {
    //   duration: 2,
    //   autoAlpha: 1,
    //   ease: Sine.easeOut,
    //   overwrite: "auto"
    // });
  }
  
  hide(elem) {
    // gsap.set(elem, {autoAlpha: 0});
  }

}
