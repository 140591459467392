<section class="title-vertical-image-animation"
         [style]="{minHeight:(data_products.length*hPage+138)+'px'}">

  <h2 class="title">Foto verticali</h2>


  <section class="prodotto" *ngFor="let product of data_products; let i = index" [id]="'prod_'+i">
    <div class="boxAnimato" *ngIf="i%2 !== 0">

      <div class="boxText">
        <div class="bloccoText sinistra">
          <h2 *ngIf="product.title">{{product.title}}</h2>
          <h3 *ngIf="product.subtitle">{{product.subtitle}}</h3>
          <p *ngIf="product.text">{{product.text}}</p>
        </div>
      </div>
      <div class="boxImg">
        <img [src]="product.image.url" [id]="'prod_img_'+i"
             class="destra"
             alt="">
      </div>


    </div>
    <div class="boxAnimato" *ngIf="i%2 === 0">

      <div class="boxImg">
        <img [src]="product.image.url" [id]="'prod_img_'+i"
             alt="">
      </div>

      <div class="boxText">
        <div class="bloccoText">
          <h2 *ngIf="product.title">{{product.title}}</h2>
          <h3 *ngIf="product.subtitle">{{product.subtitle}}</h3>
          <p *ngIf="product.text">{{product.text}}</p>
        </div>
      </div>

    </div>
  </section>
<!--  <div class="fine">-->
<!--    <h3>FINE </h3>-->
<!--  </div>-->



</section>


