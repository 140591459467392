import { Component, OnInit, Input } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  @Input() data_hero: any;
  @Input() data_aiuppie: any;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      defaults: {duration: 1},
      scrollTrigger: {
        trigger: ".hero",
        // markers: true,
        // start: "top center",
        // end: "bottom",
        // pin: true
      }
    })
    .fromTo(".hero", {y: 100, opacity: 0}, {y: 0, opacity: 1})
  }
}
