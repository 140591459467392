<section class="style-pictures-moodboard" id="{{section_id}}" *ngIf="stylePictures">

    <h2 class="style-pictures-moodboard-title">
        <span>{{labels?.moodboard?.tuoMoodboard}}</span>
    </h2>

<!--    <div class='spmreveal style-pictures-moodboard-item' *ngFor="let image of stylePictures">-->
<!--        <img [src]="image?.url" (click)="onShowImg(image?.url)">-->
<!--        <div class="moodboard-labels">-->
<!--            <h3> {{image?.categoryLabel}}</h3>-->
<!--            <h4> {{image?.name}}</h4>-->
<!--        </div>-->
<!--    </div>-->
    <ngx-masonry class="grid" [options]="myOptions" [ordered]="true">

        <div ngxMasonryItem  class='grid-item style-pictures-moodboard-item' *ngFor="let image of stylePictures, let i = index">
            <img [src]="image?.url" (click)="onShowImg(image?.url)">

            <div class="moodboard-labels">
                <h3> {{image?.categoryLabel}}</h3>
                <h4> {{image?.name}}</h4>
            </div>
        </div>

    </ngx-masonry>
</section>
