<section class="row-image-animation"  *ngIf="section.pictures">

    <!-- <h2 *ngIf="data_level.name">{{data_level.name}}</h2> -->

<!--    <div *ngFor="let image of data_level.images" class="row-image-animation-item">-->
<!--        <div class='reveal'>-->
<!--            <img [src]="image.url" [alt]="image.alt">-->
<!--        </div>-->
<!--    </div>-->
    <div *ngFor="let image of urls" id="{{section_id}}" class="row-image-animation-item">
        <div class='reveal'>
            <img [src]="image">
        </div>
    </div>
</section>
