import { Component, OnInit, Input } from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-title-image-animation',
  templateUrl: './title-image-animation.component.html',
  styleUrls: ['./title-image-animation.component.scss'],
})

export class TitleImageAnimationComponent implements OnInit {
  @Input() hero_image: any;
  @Input() aiuppie_title:string;
  @Input() section_id:string;

  constructor() { }

  ngOnInit(): void {
    // gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterViewInit(): void {

    // let title_image_container = document.querySelectorAll(".title-image-animation");
    let title_image_container = document.querySelectorAll("#"+this.section_id);
    title_image_container.forEach((container) => {
      gsap.to(container, {
        scrollTrigger: {
          trigger: container,
          scrub: true,
          pin: true,
          start: "center center",
          end: "bottom -100%",
          toggleClass: "active"
        },
        ease: "power2"
      });

      gsap.to(".title-image-animation__image", {
        scrollTrigger: {
          trigger: container,
          scrub: 0.5,
          start: "top bottom",
          end: "bottom -300%",
          toggleActions: "restart none none reset"
        },
        ease: "power2",
        y: "-30%"
      });
    });
  }

}
