import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {gsap, Sine} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

@Component({
    selector:'app-sections-left-right',
    templateUrl:'./sections-left-right.component.html',
    styleUrls:['./sections-left-right.component.scss']
})
export class SectionsLeftRightComponent implements OnInit {

    @Input() labels:any;
    @Input() sections:any;
    @Input() stylePictures:any;
    // @Output() onScopriDiPiu:any;

    @Output() onScopriDiPiu: EventEmitter<null> = new EventEmitter();
    @Output() onShowAllPics: EventEmitter<Array<string>> = new EventEmitter();


    private sections_img = [];

    constructor() {
    }

    ngOnInit():void {
        // gsap.registerPlugin(ScrollTrigger);
        this.sections_img = [];
        this.sections.map(elm => {
                let j = -1;

                const childsNumb = elm.childs.length;
                if(childsNumb){
                    while (++j < childsNumb) {
                        let child = elm.childs[j];

                        let w = -1;
                        const picLen = child.pictures.length;
                        child.picturesURL = [];
                        this.sections_img[j] = [];
                        while (++w < picLen) {
                            let pic = child.pictures[w];
                            const sp = this.stylePictures.find(stPic => stPic.id === pic);
                            if(sp){
                                // pic.url = sp.url;
                                child.picturesURL[w] = {pic:pic, url:sp.url}
                                this.sections_img[j].push(sp.url);
                            }else{
                                child.picturesURL[w] = {pic:pic, url:''}
                                this.sections_img[j].push('');
                            }


                        }

                    }

                }else{
                    const finishesNumb = elm.finishes.length;

                    while (++j < finishesNumb) {
                        let finish = elm.finishes[j];

                        finish.picturesURL = [{pic:finish.pictureId, url:finish.url}];
                        this.sections_img[j] = [finish.url];

                    }
                }
            return elm;
            }

        );


    }

    ngAfterViewInit():void {
        //
        gsap.utils.toArray(".blocco").forEach((elem:any, index:number) => {
            this.hide(elem); // assure that the element is hidden when scrolled into view
            // console.log("TextImageLeftRightAnimationComponent .gs_reveal", elem);

            ScrollTrigger.create({
                trigger:elem,
                onEnter:() => this.animateGrid(elem, index),
            });
        });
        // gsap.utils.toArray(".sections-left-right-item .gs_reveal").forEach((elem:any) => {
        //     this.hide(elem); // assure that the element is hidden when scrolled into view
        //     // console.log("TextImageLeftRightAnimationComponent .gs_reveal", elem);
        //
        //     ScrollTrigger.create({
        //         trigger:elem,
        //         onEnter:() => this.animateFrom(elem),
        //         onEnterBack:() => this.animateFrom(elem, -1),
        //         onLeave:() => this.hide(elem) // assure that the element is hidden when scrolled into view
        //     });
        // });


        gsap.utils.toArray(".sections-left-right-title").forEach((elem:any) => {
            gsap.fromTo(elem, {
                maskSize:"+=0", webkitMaskSize:"+=0",
                transformOrigin:"top", autoAlpha:.8
            }, {

                autoAlpha:1,
                maskSize:'+=300%',
                webkitMaskSize:"+=300%",
                ease:Sine.easeInOut,

                scrollTrigger:{
                    start:"top 80%",
                    end:"top 40%",
                    markers:false,
                    trigger:elem,
                    scrub:true,
                    // id:"title "
                }
            });
        });
    }


    animateGrid(elem, index, direction?) {
        gsap.fromTo(elem, {scale:3, transformOrigin:"top", autoAlpha:0}, {

            scale: 1,
            autoAlpha:1,
            ease: Sine.easeInOut,

            scrollTrigger: {
                start:"top 80%",
                end:"top 50%",
                markers:false,
                trigger: elem,
                scrub: true,
                id:"cards "+index
            }
        });
    }


    animateFrom(elem, direction?) {
        direction = direction | 1;

        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        gsap.fromTo(elem, {x:x, y:y}, {
            duration:1.25,
            x:0,
            y:0,
            ease:"expo",
            overwrite:"auto"
        });
        gsap.fromTo(elem, {autoAlpha:0}, {
            duration:2,
            autoAlpha:1,
            ease:Sine.easeOut,
            // overwrite: "auto"
        });
    }

    hide(elem) {
        // gsap.set(elem, {autoAlpha:0});
    }


    onClickScopri = (child) =>{
        // console.log('SectionsLeftRightComponent - onClickScopri - child >>> ', child  );
        this.onScopriDiPiu.emit(child);
    }

    onClickGallery = (i) =>{
        // console.log('SectionsLeftRightComponent - onClickGallery - this.sections_img[i] >>> ', this.sections_img[i]  );
        console.log(this.sections_img, 'SectionsLeftRightComponent - onClickGallery - this.sections_img[i] >>> ', this.sections_img[i]  );
        this.onShowAllPics.emit(this.sections_img[i]);
    }

}
